import { Link } from 'react-router-dom';
import { useEffect, useState, useContext } from "react";
import { Button } from "../common/Button";
import { handleInputChange } from "../helpers";
import axios from "axios";
import "./user.css";
import { FlashContext } from '../flash-context';
import UserSearch from "../common/Search";
import createJob1 from '../assets/illustrations/bluesmith_createJob.svg';
import { UserContext } from "../user-context";
import Table from "../common/Table";
import MachineMaterialTable from '../common/MachineMaterialTable';


export default function CreateJob() {
  const { flash, setFlash } = useContext(FlashContext);
  const { user, setUser } = useContext(UserContext);

  // const flashValue = { flash, setFlash };
  const [invite, setInvite] = useState([]);
  const [showNext, setShowNext] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState(false);
  const [application, setApplication] = useState("");
  const [consult, setConsult] = useState(false);
  const [clinical, setClinical] = useState(false);
  const [designHub, setDesignHub] = useState(false);
  const [shareable, setShareable] = useState(false);
  const [usedForClass, setUsedForClass] = useState(false);
  const [courseNumber, setCourseNumber] = useState(false);
  const [terms, setTerms] = useState(false);
  const [jobFilesAndQuantities, setJobFilesAndQuantities] = useState(false);
  //const [jobFiles, setJobFiles] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [printerId, setPrinterId] = useState(false);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [autoApproveEstimate, setAutoApproveEstimate] = useState(false);
  const [autoApproveValue, setAutoApproveValue] = useState(0);
  const [showSubmitSpinner, setShowSubmitSpinner] = useState(false);

  const addColaborators = () => {
    return <div>
      <label htmlFor="invite" className="form-label">Add Collaborators</label>
      <UserSearch id="invite" handleChange={(e) => setInvite(e)} />
    </div>
  }

  const clinicalCheck = () => {
    return <div className="form-check form-switch">
      <input onChange={e => handleInputChange(e, setClinical)} className="form-check-input" type="checkbox" role="switch" id="clinicalService" defaultChecked={clinical} />
      <label className="form-check-label label-title" htmlFor="clinicalService">Clinical Service</label>
      <label className="form-check-label label-body" htmlFor="clinicalService">This service gives prioritization of sensitive clinical requests on the queue of the requested machine(s). Support structure removal, model finishing, and pre and post-print consultation are given for each order.</label>
    </div>
  }

  const consultCheck = () => {
    return <div className="form-check closing-item">
      <input onChange={e => handleInputChange(e, setConsult)} className="form-check-input" type="checkbox" value="" id="consultation" checked={consult} />
      <label className="form-check-label label-body" htmlFor="consultation">Consultation <span>(One of our consultants will reach out to you if you have not communicated with us.)</span></label>
      {consult ? <p>Schedule an appointment <a href="https://outlook.office365.com/owa/calendar/Bluesmith3DPrintingConsultation@ProdDuke.onmicrosoft.com/bookings/" target="_blank">here</a></p> : null}
    </div>
  }

  const designHubCheck = () => {
    return <div className="form-check form-switch">
      <input onChange={e => handleInputChange(e, setDesignHub)} className="form-check-input" type="checkbox" role="switch" id="designHub" defaultChecked={designHub} />
      <label className="form-check-label label-title" htmlFor="designHub">DesignHub Prototyping Service</label>
      <label className="form-check-label label-body" htmlFor="designHub">Select this option if the job is coming from an existing DesignHub project or if coordination of iterative testing and modeling is needed.</label>
    </div>
  }

  const marketingCheck = () => {
    return <div className = "form-check form-switch">
      <input onChange={e => handleInputChange(e, setShareable)} className="form-check-input" type="checkbox" role="switch" id="isShareable" checked={shareable} />
      <label className="form-check-label label-title" htmlFor="isShareable">Can we share this print and use it in our promotional materials?</label>
    </div>
  }

  const bypassEstimate = () => {
    return <div className = "form-check form-switch">
      <input onChange={e => handleInputChange(e, setAutoApproveEstimate)} className="form-check-input" type="checkbox" role="switch" id="autoApproveEstimate" checked={autoApproveEstimate} />
      <div>
      <label className="form-check-label label-title me-3" htmlFor="autoApproveEstimate">Auto-approve Estimate</label> 
      {autoApproveEstimate ? (
        <div class="input-group w-25 mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">$</span>
          </div>
          <input onChange={e => handleInputChange(e, setAutoApproveValue)} type="text" id="autoApproveValue" className="form-control" placeholder="Enter amount" value={autoApproveValue} aria-describedby="basic-addon1"/>
        </div>) : ('')}
      </div>
      <label className="form-check-label label-body" htmlFor="autoApproveEstimate">Select this option if you would like to automatically approve your estimate if it is below a threshold you choose. This improves turnaround time, but does not change anything else about how we handle your request.</label>
    </div>
  }

  const handleUsedForClass = (e) => {
    setUsedForClass(e.target.checked);
    if (!e.target.checked) {
      setCourseNumber(false);
    }
  }

  const usedForClassCheck = () => {
    return <div>
      <div className="form-check form-switch">
        <input onChange={e => handleUsedForClass(e)} className="form-check-input" type="checkbox" role="switch" id="usedForClass" defaultChecked={usedForClass} />
        <label className="form-check-label label-title" htmlFor="usedForClass">Is this print meant for a class?</label>
        {courseNumberField()}
      </div>
    </div>
  }

  const courseNumberField = () => {
    return <div>
      <input onChange={e => handleInputChange(e, setCourseNumber)} type="text" className="form-control" id="courseNumber" aria-describedby="courseNumber" placeholder="Course number" required hidden={!usedForClass} value={courseNumber || ""} />
    </div>
  }

  const handleAddFile = (event) => {
    // let addFiles = Array.from(jobFiles);
    // for (let i = 0; i < event.target.files.length; i++) {
    //   addFiles.push(event.target.files[i])
    // }
    // setJobFiles(addFiles);
    // console.log("jobFiles-in-handle-add-file: ", addFiles);
    let addFiles = Array.from(jobFilesAndQuantities);
    for (let i = 0; i < event.target.files.length; i++) {
      addFiles.push({'file': event.target.files[i], 'quantity': 1})
    }
    setJobFilesAndQuantities(addFiles);
    console.log("jobFiles-in-handle-add-file: ", addFiles);
  };

  const handleRemoveFile = (jobFile) => {
    // let newFiles = Array.from(jobFiles);
    // newFiles.splice(jobFile, 1);
    // setJobFiles(newFiles);
    let newFiles = Array.from(jobFilesAndQuantities);
    newFiles.splice(jobFile, 1);
    setJobFilesAndQuantities(newFiles);
  };

  const updateFileQuantity = (event, index) => {
    let newQuantity = Array.from(jobFilesAndQuantities);
    newQuantity[index]['quantity'] = event.target.value;
    setJobFilesAndQuantities(newQuantity);
  }

  const fileUpload = () => {
    // let showFiles = [];
    // for (let i = 0; i < jobFiles.length; i++) {
    //   console.log(jobFiles)
    //   showFiles.push(
    //     <div>
    //       <li key={"f" + i}>
    //         {jobFiles[i].name + "  "}
    //         <Button 
    //           className="btn btn-outline-secondary btn-remove" 
    //           label= "x"
    //           onClick={() => setJobFiles(false)} 
    //         />
    //       </li>
    //     </div>)
    // }
    let showFiles = [];
    for (let i = 0; i < jobFilesAndQuantities.length; i++) {
      showFiles.push({
        "file": <p key={i}>{jobFilesAndQuantities[i]['file'].name}</p>,
        "quantity": <input onChange={e => updateFileQuantity(e, i)} type="number" className="form-control" id="quantity" aria-describedby="file-quantity" required key={jobFilesAndQuantities[i]['quantity']} min={1} defaultValue={jobFilesAndQuantities[i]['quantity']} />,
        "delete": <Button className="btn btn-outline-secondary btn-remove"
          dataCy="create-job-remove-file"
          label="x"
          onClick={() => handleRemoveFile(i)}
        />
      })
    }

    // adding these lines to tell the user no files are selected
    if (jobFilesAndQuantities.length === 0) {
      setJobFilesAndQuantities(false);
    }

    return <div>
      <label className="form-label" htmlFor="files">Upload files</label><span className="red-star">*</span>
      <p className="special-info">I confirm all .STL model units are in millimeters.</p>
      <div className="upload-btn-wrapper">
        <button className="btn btn-primary btn-smaller" type="button">Add Files</button>
        <input name="files" onChange={e => handleAddFile(e)} className="form-control" type="file" id="files" multiple />
        {/* ======= */}
        {/* this is what I think kylie had that conflicted with sandra */}
        {/* <button className="btn btn-smaller" type="button">Select</button>
        <input name="files" onChange={e => {handleInputChange(e, setJobFiles)}} 
          onClick={e => {e.target.value = null}}
          className="form-control" type="file" id="files" multiple /> */}
        {/* >>>>>>> @{-1} */}
      </div>
      <div className="preview">
        {jobFilesAndQuantities ?
          <div>
            <label>Files uploaded:</label>

            {<Table colName={["Files", "Quantity", "Delete"]} data={showFiles} noHover={true} />}
          </div> : <p>No files currently selected for upload</p>}
      </div>
    </div>
  }

  const jobApplication = () => {
    return <div>
      <label htmlFor="JobApplication" className="form-label">Job Application</label><span className="red-star">*</span>
      <label className="form-check-label label-body" htmlFor="clinicalService">What is the final use of the print? Elaborate on any of the following intents: clinical application, biocompatibility, sterilizable, high temperatures, UV exposure, long duress, high impact, water-tight, etc...</label>
      <textarea onChange={e => handleInputChange(e, setApplication)} data-cy="create-job-app" className="form-control" id="JobApplication" rows="3" placeholder="A description of job use and material property needs." required defaultValue={application}></textarea>
    </div>
  }

  const jobDescription = () => {
    return <div>
      <label htmlFor="JobDescription" className="form-label">Job Description</label><span className="red-star">*</span>
      <textarea onChange={e => handleInputChange(e, setDescription)} className="form-control" id="JobDescription" rows="3" placeholder="A brief summary of this job." required defaultValue={description}></textarea>
    </div>
  }

  const jobDeadline = () => {
    let min;
    const date = new Date()
    if (user.type === "HighRoller") {
      date.setDate(date.getDate() + 1)
    } else if (user.type === "BsClient") {
      date.setDate(date.getDate() + 7)
    }
    min = date.toISOString().split("T")[0]
    return <div>
      <label htmlFor="JobDeadline" className="form-label">Job Preferred Deadline</label>
      <input onChange={e => handleInputChange(e, setDeadline)} type="date" min={min} className="form-control" id="JobDeadline" defaultValue={deadline} />
    </div>
  }

  const jobName = () => {
    return <div>
      <label htmlFor="JobName" className="form-label">Job Name</label><span className="red-star">*</span>
      <input onChange={e => handleInputChange(e, setName)} type="text" className="form-control" id="JobName" aria-describedby="JobName" placeholder="A short descriptive name to identify this job." required defaultValue={name} />
    </div>
  }

  const submitForm = (e) => {
    e.preventDefault()
    if (allowSubmit) {
      setAllowSubmit(false);
      setShowSubmitSpinner(true);
      const form = new FormData();

      console.log(form);
      console.log(invite)

      Array.from(jobFilesAndQuantities).forEach(file => {
        form.append("job_files[]", file['file']);
        form.append("file_quantities[]", file['quantity']);
      });
      invite.forEach(i => {
        form.append("duids[]", i.value);
      })
      selectedMaterials.forEach(m => {
        form.append("materials[]", m.value);
      })
      if (autoApproveEstimate) {
        form.append("auto_approve_value", autoApproveValue);
      }

      form.append("name", name);
      form.append("description", description);
      form.append("deadline", deadline);
      form.append("application", application);
      form.append("wants_consultation", consult);
      form.append("is_clinical_service", clinical);
      form.append("is_design_hub", designHub);
      form.append("terms_of_service", terms);
      form.append("is_shareable", shareable);
      form.append("machine_id", printerId);
      form.append("course_number", courseNumber);
      
      const options = {
        method: 'POST',
        url: 'jobs',
        headers: {
          'Content-Type': 'multipart/form-data',
          'enctype': 'multipart/form-data'
        },
        data: form
      };

      axios.request(options).then(function (response) {
        console.log(response.data);
        setShowSubmitSpinner(false);
        if (response.data.error) {
          console.log(response.data.messages);
          setFlash({ type: "danger", message: response.data.message });
          setAllowSubmit(true)
        } else {
          //console.log(response.data.data)
          window.location.pathname = './jobs';
        }
      }).catch(function (error) {
        setShowSubmitSpinner(false);
        console.log(error);
        let message = error.message;
        if (error.code === "ERR_BAD_REQUEST") {
          message = "Please fill out all the required fields.";
        }
        setFlash({ type: "danger", message: message });
      });
    }
  }

  useEffect(() => {
    //console.log(`status: name:${name} description: ${description} application:${application} jobFilesAndQuantities:${jobFilesAndQuantities} terms:${terms}`)
    if (name && description && application && jobFilesAndQuantities && terms) {
      if (usedForClass && !courseNumber) {
        console.log("not allow submission");
        setAllowSubmit(false);
      } else {
        console.log("allow submission");
        setAllowSubmit(true);
      }
    } else {
      console.log("not allow submisssion")
      setAllowSubmit(false);
    }
  }, [name, description, application, jobFilesAndQuantities, terms, usedForClass, courseNumber])

  const termsCheck = () => {
    return <div className="form-check">
      <input onChange={e => handleInputChange(e, setTerms)} className="form-check-input" type="checkbox" value="" id="terms" required defaultChecked={terms} />
      <label className="form-check-label label-body" htmlFor="terms">
        I accept the terms.
      </label><span className="red-star">*</span>
    </div>
  }

  const handleMachineMaterialUpdate = (printerId, materials) => {
    setPrinterId(printerId);
    setSelectedMaterials(materials);
  }

  const selectPrinterAndMaterials = () => {
    return <MachineMaterialTable
      selectedPrinter={printerId}
      selectedMaterials={selectedMaterials}
      handleMachineMaterialUpdate={handleMachineMaterialUpdate}
    />
  }

  return <>
    <div className="container wrapper jobs">
      <div className="main">
        <h1>Create a Job </h1>
        <form>
          {!showNext ?
            <div className="first-part">
              {jobName()}
              {jobDescription()}
              {jobApplication()}
              <br/>
              {usedForClassCheck()}
              {marketingCheck()}
              {fileUpload()}
              {addColaborators()}
              <Button className="btn btn-primary" dataCy="create-job-next-page" type="button" label="Next Step: Choose Materials" onClick={() => setShowNext(true)} />
            </div>
            :
            <div className="second-part">
              <Button className="btn btn-primary" dataCy="create-job-prev-page" type="button" label="Go back" onClick={() => setShowNext(false)} />
              <p className="special-info">Check out the <Link aria-current="page" data-cy="create-job-to-capabilities-page" to="/capabilities">Bluesmith Capabilities page</Link> to learn more about the different options.</p>
              
              {selectPrinterAndMaterials()}
              {consultCheck()}
              {jobDeadline()}
              <label className="form-label sub-title">Additional Service Offered</label>
              {clinicalCheck()}
              {designHubCheck()}
              {bypassEstimate()}
              <img
                src={createJob1}
                alt='clinical'
                className='illustrations'
              />
              <div>
                <h2>Terms of Service</h2>
                <h4>Contract of agreement</h4>
                <ul>
                  <li>I agree that these terms are reasonable and I will abide by them in order to use this service.</li>
                  <li>I understand charges vary between jobs, and cost estimates may change during job processing.</li>
                  <li>I understand jobs with small or complex parts increase likelihood of job failure.</li>
                  <li>I confirm all model units are in millimeters.</li>
                </ul>
                <h4>Services</h4>
                <p>Our staff will work diligently to complete your print job in a timely manner. Printer and material availability, job complexity, consultations, and clinical priority work all contribute to possible delays in service so we cannot guarantee a delivery time. However we will communicate regularly and offer additional status updates upon request.

                  3D printing is an experimental technology that often fails. Equipment failures are not unusual. Our staff will make every attempt to print your object successfully. If equipment breaks or the print failed without cause we will attempt to print it again. The likelihood of failure increases significantly with more difficult-to-print models. If the failure is a result of simply submitting too complex of a model, customers will still be charged an attempt fee of half the price of the initial quote. Users can accept the failed job or we can make a second attempt at the print; in this scenario, Bluesmith technicians can make additional attempts at 50% of the original estimate.

                  The user is responsible for the risks associated in the end use application of their 3D printed parts due to the experimental nature of the methods and materials of these technologies.</p>
                <h4>Pricing</h4>
                <p>Print charges are estimated based on the material they will use and the time it takes for staff to process them. Actual costs of completed jobs may vary from from the issued estimate, and you are responsible for final cost within a reasonable amount increase.

                  The cost of 3D printing varies widely and is based on the technology and amount of material used. Material prices are liable to change frequently and thus our printing costs may also change without notice for new jobs.</p>
              </div>
              {termsCheck()}
              <button onClick={submitForm} data-cy="creat-job-submit-button" type="submit" className="btn btn-primary" disabled={!allowSubmit}>
                {showSubmitSpinner ? (<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>) : ('')}
                Submit Job
              </button>
            </div>
          }
        </form>
      </div>
    </div>
  </>
}