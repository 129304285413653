import { useContext, useState } from "react";
import axios from "axios";
import ManageClients from "../../common/ManageClients";
import { FlashContext } from "../../flash-context";
import { Job } from "../../models/job";
import OldMachineMaterialTable from "../../common/OldMachineMaterialTable";
import MachineMaterialTable from "../../common/MachineMaterialTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons'

export default function AdminDetailTab(props) {
    const { flash, setFlash } = useContext(FlashContext);
    const [editDetails, setEditDetails] = useState(false);
    const [description, setDescription] = useState(props.job.description);
    const [application, setApplication] = useState(props.job.application);
    const [consultation, setConsultation] = useState(props.job.wants_consultation);
    const [clinicalService, setClinicalService] = useState(props.job.is_clinical_service);
    const [designHub, setDesignHub] = useState(props.job.is_design_hub);
    const [shareable, setShareable] = useState(props.job.is_shareable);
    const [courseNumber, setCourseNumber] = useState(props.job.course_number);

    const jobDeadline = () => {
        return <p>
            <strong>Preferred Deadline: </strong>
            <input onChange={e => handleDeadlineUpdate(e)} type="date" className="form-control" id="JobDeadline" defaultValue={props.job.deadline} />
        </p>
    }

    const handleDeadlineUpdate = (e) => {
        if (window.confirm("Are you sure you want to update?")) {
            const form = new FormData();
            form.append("deadline", e.target.value);
            const options = {
                method: 'PUT',
                url: `jobs/${props.job.id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                },
                data: form
            };

            axios.request(options).then(function (response) {
                if (response.data.error) {
                    console.log(response.data.messages)
                } else {
                    setFlash({ type: "success", message: "You have updated the deadline." })
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    }

    const handleMachineMaterialUpdate = (selectedPrinter, selectedMaterials) => {
        const selectedMaterialIds = selectedMaterials.map((m) => m.value);
        let params = {'machine_id': selectedPrinter, 'material_ids': selectedMaterialIds};
        Job.updateMachineMaterialCombos(params, props.job.id).then(() => {
            props.getJob();
        });
    }

    const machineMaterialTable = () => {
        let uniqueMachineIds = [];
        let materialIds = [];
        props.job.machine_material_combos.forEach((mmc) => {
            materialIds.push({value: mmc.material_id, label: mmc.material_name});
            if (!uniqueMachineIds.includes(mmc.machine_id)) {
                uniqueMachineIds.push(mmc.machine_id);
            }
        })
        if (uniqueMachineIds.length <= 1) {
            return <MachineMaterialTable
                selectedPrinter={uniqueMachineIds[0] || 0}
                selectedMaterials={materialIds}
                handleMachineMaterialUpdate={handleMachineMaterialUpdate}
            />
        } else {
            return <OldMachineMaterialTable job={props.job} getJob={props.getJob} />
        }
    }

    const saveChanges = () => {
        const form = new FormData();
        if (description != props.job.description) { form.append("description", description) };
        if (application != props.job.application) { form.append("application", application) };
        if (consultation != props.job.wants_consultation) { form.append("wants_consultation", consultation) };
        if (clinicalService != props.job.is_clinical_service) { form.append("is_clinical_service", clinicalService) };
        if (designHub != props.job.is_design_hub) { form.append("is_design_hub", designHub) };
        if (shareable != props.job.is_shareable) { form.append("is_shareable", shareable) };
        if (courseNumber != props.job.course_number) { form.append("course_number", courseNumber) };

        props.updateJob("batch", form);
        setEditDetails(false);
    }

    const cancelChanges = () => {
        setDescription(props.job.description);
        setApplication(props.job.application);
        setConsultation(props.job.wants_consultation);
        setClinicalService(props.job.is_clinical_service);
        setDesignHub(props.job.is_design_hub);
        setShareable(props.job.is_shareable);
        setCourseNumber(props.job.course_number);
        setEditDetails(false);
    }

    const descriptionAndApplication = () => {
        if (editDetails) {
            return <>
                <label className="form-label"><strong>Description: </strong></label><input onChange={(e) => setDescription(e.target.value)} value={description} className="form-control mb-2" type="text" id="description"/>
                <label className="form-label"><strong>Application: </strong></label><input onChange={(e) => setApplication(e.target.value)} value={application} className="form-control mb-2" type="text" id="application"/>
            </>
        } else {
            return <>
                <p data-cy="job-description"><strong>Description: </strong>{props.job.description}</p>
                <p data-cy="job-application"><strong>Application: </strong>{props.job.application}</p>
            </>
        }
    }

    const checkboxesAndCourseNumber = () => {
        if (editDetails) {
            return <>
                <div className="form-check"><label className="form-check-label"><strong>Consultation </strong></label><input onClick={(e) => setConsultation(!consultation)} checked={consultation} className="form-check-input mb-2" type="checkbox" id="consultation"/></div>
                <div className="form-check"><label className="form-check-label"><strong>Clinical Service </strong></label><input onClick={(e) => setClinicalService(!clinicalService)} checked={clinicalService} className="form-check-input mb-2" type="checkbox" id="clinical_service"/></div> 
                <div className="form-check"><label className="form-check-label"><strong>DesignHub Service </strong></label><input onClick={(e) => setDesignHub(!designHub)} checked={designHub} className="form-check-input mb-2" type="checkbox" id="designhub"/></div>
                <div className="form-check"><label className="form-check-label"><strong>Shareable in promotional materials </strong></label><input onClick={(e) => setShareable(!shareable)} checked={shareable} className="form-check-input mb-2" type="checkbox" id="shareable"/></div>
                <label className="form-label"><strong>Course Number: </strong></label><input onChange={(e) => setCourseNumber(e.target.value)} value={courseNumber} className="form-control mb-2" type="text" id="course_number"/>
            </>
        } else {
            return <>
                <p data-cy="job-consultation"><strong>Consultation: </strong>{props.job.wants_consultation ? "Yes" : "No"}`</p>
                <p data-cy="job-clinical"><strong>Clinical Service: </strong>{props.job.is_clinical_service ? "Selected" : "Not Selected"}</p>
                <p data-cy="job-design-hub"><strong>DesignHub Service: </strong>{props.job.is_design_hub ? "Selected" : "Not Selected"}</p>
                <p><strong>Project shareable in our promotional materials: </strong> {props.job.is_shareable ? "Yes" : "No"}</p>
                <p><strong>Course Number: </strong>{props.job.course_number === "false" ? "Not Applicatble" : props.job.course_number}</p>`
            </>
        }
    }

    return (
        <>
            <div>
                <h3 className="d-inline pe-1">Detail</h3>
                { editDetails ? 
                    <>
                        <button onClick={saveChanges} className="btn btn-primary btn-sm" title="Save"><FontAwesomeIcon icon={faFloppyDisk}/></button>
                        <button onClick={cancelChanges} className="btn btn-primary btn-sm" title="Cancel"><FontAwesomeIcon icon={faX}/></button>
                    </> : 
                    <button onClick={() => setEditDetails(true)} className="btn btn-primary btn-sm" title="Edit"><FontAwesomeIcon icon={faPencil}/></button> 
                }
            </div>
            {descriptionAndApplication()}
            <p data-cy="job-start-date"><strong>Start Date: </strong>{props.job.pretty_created_at}</p>
            {jobDeadline()}
            {machineMaterialTable()}
            {checkboxesAndCourseNumber()}
            <h3>Clients:</h3>
            <ManageClients dataCy="table-collaborators" job={props.job} getJob={props.getJob} />
        </>
    )
}