import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../common/Button";
import Tabs from "../common/Tabs";
import axios from "axios";
import ChatView from "../common/chat/ChatView";
import { FlashContext } from "../flash-context";
import '../admin/admin.css';
import AdminChecklist from "./AdminChecklist";
import AdminCostTab from "./viewJobTabs/AdminCostTab";
import AdminFileTab from "./viewJobTabs/AdminFileTab";
import AdminDetailTab from "./viewJobTabs/AdminDetailTab";
import { formatJobState } from "../helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Job } from "../models/job";
import { User } from "../models/user";
import Select from 'react-select';
import { UserContext } from "../user-context";

export default function AdminViewJob() {
  const { id } = useParams();
  let navigate = useNavigate();
  const [job, setJob] = useState({});
  const [admins, setAdmins] = useState([]);
  const { flash, setFlash } = useContext(FlashContext);
  const [stateCheck, setStateCheck] = useState({});
  const [reset, setReset] = useState(false);
  const [staffers, setStaffers] = useState([]);
  const [editStaffers, setEditStaffers] = useState(false);
  const { user, setUser } = useContext(UserContext);


  useEffect(() => {
    getJob();
    getAdmins();
  }, [])

  useEffect(() => {
    updateStateChecks(job);
  }, [job])

  useEffect(() => {
    if (job.send_mail === false){
      //console.log("Im read right now. my emails are suspended");
      setFlash({type: "warning", message: <span className="flash-button-wrapper">Emails for <span>{job.name}</span> are suspended!! 😲 {toggleEmailButton()}</span> });
    }
  }, [job.send_mail])


  const archiveBtn = (job) => {
    return job.archived ? <Button id="archive" className="btn btn-primary" label="undo Archive" onClick={() => handleArchive(false)} /> : <Button id="archive" className="btn btn-primary" label="Archive" onClick={() => handleArchive(true)} />
  }


  const assignJob = () => {
    let options = admins.map((m) => { return { value: m.id, label: m.display_name } })
    return <span className="assign-to">Assign to:
      <Select isMulti options={options} id="name" onChange={e => setStaffers(e)} value={staffers} />
      <Button className="btn btn-primary" label="Assign" onClick={() => getStafferIds(job.id)} />
      <Button className="btn btn-primary" label="Cancel" onClick={() => handelCancel()} />
    </span>
  }

  const chatWindow = () => {
    return <div className="chat-section">
        <ChatView jobId={id} type="cable" />
    </div>
  }

  const costs = () => {
    return <AdminCostTab id={id} job={job} getJob={getJob} reset={reset} setReset={setReset} updateJob={updateJob} />
  }


  const detail = () => {
    return <AdminDetailTab id={id} job={job} getJob={getJob} updateJob={updateJob}/>
  }


  const files = () => {
    return <AdminFileTab id={id} job={job} getJob={getJob} />
  }


  const getJob = () => {
    Job.get(id).then((response) => {
      if (response.response) {
        console.log("RESPONSE: ", response);
      } else {
        let data = response.data.data;
        console.log("api data", data);
        setJob(data);
        let assignedStaffers = [];
        data?.staffers?.map((a) => {
            assignedStaffers.push({ value: a.id, label: a.display_name });
        })
        setStaffers(assignedStaffers)
        updateStateChecks(data);
      }
    });
  }


  const getAdmins = () => {
    User.admins_and_employees().then((response) => {
      if (response.response) {
        console.log(response);
      } else {
        let data = response.data;
        setAdmins(data);
      }
    });
  }


  const getStafferIds = (jobId) => {
    if (window.confirm("Are you sure to set the staffs and send confirmation emails to them?")) {
      let ids = staffers.map(a => a.value);
      updateStaffers(jobId, ids)
    }
  }


  const handleArchive = (value) => {
    let prompt = value ? "Are you sure you want to archive this job?" : "Are you sure you want to revive this job?";
    if (window.confirm(prompt)) {
      const form = new FormData();
      form.append("archived", value);
      form.append("skip_state_check", true);

      const options = {
        method: "PUT",
        url: `jobs/${id}/`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'enctype': 'multipart/form-data'
        },
        data: form
      }

      axios.request(options).then(function (response) {
        console.log(response.data);
        if (response.data.error) {
          setFlash({ type: "danger", message: "Unable to Archive. Error Unknown" });
        } else {
          setFlash({ type: "success", message: value ? "Job has been archived " : "Job has been revived" });
          navigate("/admin");
        }
      }).catch(function (error) {
          console.error(error);
      });
    }
  }


  const handleEmail = (type) => {
    const form = new FormData();
    let url = `jobs/${id}/email_reminder`;
    if (type === "payment") {
      url = `jobs/${id}/payment_reminder`;
    }

    const options = {
      method: "PUT",
      url: url,
      headers: {
          'Content-Type': 'multipart/form-data',
          'enctype': 'multipart/form-data'
      },
      data: form
    };

    axios.request(options).then(function (response) {
      if (response.data.error) {
        console.log(response.data);
      } else {
        getJob();
        setFlash({ type: "success", message: "Email Sent!" });
      }
    }).catch(function (error) {
        console.error(error);
    });
  }

  const handelCancel = () => {
    let assignedStaffers = [];
    job.staffers?.map((a) => {
      assignedStaffers.push({ value: a.id, label: a.display_name });
    })
    setStaffers(assignedStaffers)
    setEditStaffers(false)
  }

  const handleAssignToMe = (jobId) => {
    let ids = staffers.map(a => a.value);
    let userId = user.id;
    if (!ids.includes(userId)){
      ids.push(userId);
      updateStaffers(jobId, ids);
    } else {
      setFlash({ type: "danger", message: "You're already assigned to this job!" });
    }
  }

  const jobDetailsHeader = () => {
    return <div className="job-detail-header">
      <h1>{job.name}</h1>
      <h2>Job # {id} - {job.state ? showStatus() : "Status Unknown"}</h2>
      {job.archived ? <span className="badge text-bg-warning">Archived</span> :
        (job.picked_up && job.paid ? <span className="badge text-bg-secondary">Complete</span> : <span className="badge text-bg-success">Active</span>)}
      {editStaffers ? assignJob() : showStaffers()}
      {onusToggle()}
    </div>
  }

  const transferOnus = () => {
    const attrs = {skip_state_check: true, onus: job.onus_state === "Staff" ? "client" : "staff"}
    const job_updates = new Job(attrs);
    job_updates.update(job.id).then((response) => getJob()).catch((err) => console.log(err));
  }

  const onusToggle = () => {
    const editOnus = <Button className="btn btn-primary" label={`Transfer to ${job.onus_state === "Staff" ? "Client" : "Staff"}`} onClick={transferOnus}/>
    return <span className="assign-to"><strong>Pending: </strong>{job.onus_state ? job.onus_state : "None"}
      {user.type === "Admin" ? editOnus : ""}
    </span>
  }

  const setInvoiceToMatchEstimate = () => {
    const form = new FormData();
    form.append("bs_job_id", job.id);
    const options = {
      method: "POST",
      url: 'add_estimate_to_invoice',
      data: form
    };

    axios.request(options).then(function (response) {
      if (response.data.error) {
        console.log(response.data);
      } else {
        getJob();
        setFlash({ type: "success", message: "Updated!" });
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  
  const showStaffers = () => {
    let displayStaffers = <i>None</i>;
    if (job.staffers?.length > 0) {
      displayStaffers = job.staffers.map(s => s.display_name);
      displayStaffers = <span>{displayStaffers.join(', ')}</span>
    }
    return <span className="assign-to"><strong>Assigned to: </strong>{displayStaffers}
      <Button className="btn btn-primary" label="Change Staffer" onClick={() => setEditStaffers(true)} />
      {staffers.find(s => s.value === user.id) ? "" :<Button className="btn btn-link" label="Assign to me" onClick={() => handleAssignToMe(job.id)} />}
    </span>
  }


  const showStatus = () => {
    const statusList = ['Needs Estimate', 'Awaiting Client Approval', 'Start printing', 'Post-process print',
    'Send invoice to client', 'Client paying', 'Place job for pick up', 'Waiting client pick up', 'Complete']
  
    let status;
    if (stateCheck.picked_up){
      if (!stateCheck.paid){
        status = statusList[5];
      } else {
        status = statusList[8];
      }
    } else if (stateCheck.staged){
      status = statusList[7];
    } else if (stateCheck.paid){
      status = statusList[6];
    } else if (stateCheck.cost){
      status = statusList[5];
    } else if (stateCheck.post_complete){
      status = statusList[4];
    } else if (stateCheck.initial_complete){
      status = statusList[3];
    } else if (stateCheck.estimate_approved_by){
      status = statusList[2];
    } else if (stateCheck.estimate){
      status = statusList[1];
    } else if (stateCheck.submitted){
      status = statusList[0];
    }
    return status
  }


  const tabs = () => {
    return <>
      <div className="mobile-tabs">
        <Tabs tabs={[{ id: "Progress", title: <FontAwesomeIcon icon={faSpinner} /> }, { id: "Detail", title: <FontAwesomeIcon icon={faInfo} /> },
        { id: "Files", title: <FontAwesomeIcon icon={faFile} /> }, { id: "Costs", title: <FontAwesomeIcon icon={faCreditCard} /> }]}
        content={[<AdminChecklist setInvoiceToMatchEstimate={setInvoiceToMatchEstimate} stateCheck={stateCheck} updateJob={updateJob} />, detail(), files(), costs()]} />
      </div>
      <div className="desktop-tabs">
        <Tabs tabs={["Progress", "Detail", "Files", "Costs"]} content={[<AdminChecklist setInvoiceToMatchEstimate={setInvoiceToMatchEstimate} stateCheck={stateCheck} updateJob={updateJob} />, detail(), files(), costs()]} />
      </div>
    </>
  }

  const toggleEmails = (jobId) => {
    // new Job means it creates an instance of Job
    let jobEmails = new Job({ id: jobId })
    jobEmails.toggle_mail().then((response) => {
      if (response.response) {
        console.log(response.response)
      } else {
        getJob();
        setFlash({type: "success", message: job.send_mail ? "Email suspended" : "Emails enabled" });
      }
    });
  }


  const toggleEmailButton = () => {
    return <Button id="mail-toggler" className="btn btn-primary" label={job.send_mail ? "Suspend Emails" : "Enable Emails"} onClick={() => toggleEmails(job.id)} />        
  }

  const updateJob = (name, value) => {
    console.log(name, value)
    let form = new FormData();
    let url = `jobs/${id}`;
    if (name === "set_estimate" || name === "set_invoice") {
      url = `jobs/${id}/${name}`;
    } else if (name === "batch") {
      form = value;
    }else {
      form.append(name, value);
    }

    const options = {
      method: "PUT",
      url: url,
      headers: {
          'Content-Type': 'multipart/form-data',
          'enctype': 'multipart/form-data'
      },
      data: form
    };

    axios.request(options).then(function (response) {
      if (response.data.error) {
        console.log(response.data);
      } else {
        if (name === "set_estimate" && job.auto_approve_value) {
          setInvoiceToMatchEstimate();
        } else {
          getJob();
        }
        setFlash({ type: "success", message: "Updated!" });
      }
    }).catch(function (error) {
        console.error(error);
    });
  }


  const updateStaffers = (jobId, ids) => {
    let job = new Job({ id: jobId });
    job.setAdmins(ids).then((response) => {
      if (response.response) {
        console.log(response.response)
      } else {
        getJob();
        setFlash({ type: "success", message: "Staff is set!" });
        setEditStaffers(false);
      }
    });
  }


  const updateStateChecks = (data) => {
    let newStateCheck = { submitted: true, estimate: false, estimate_approved_by: false, initial_complete: false, post_complete: false, cost: false, paid: false, staged: false, picked_up: false };
    for (const name of Object.keys(newStateCheck)) {
      if (newStateCheck[name] != data[name]){
      }
      if (name === "submitted") {
        newStateCheck[name] = true;
      } else if (name === "estimate" && data[name]) {
        newStateCheck[name] = true;
      } else if (name === "cost" && data[name]) {
        newStateCheck[name] = true;
      } else {
        newStateCheck[name] = data[name];
      }
    }
    setStateCheck(newStateCheck);
    console.log("check:", newStateCheck);
  }

  return <>
    <div>
      <div className="admin-main-grid">
        <div className="job-detail">
         {jobDetailsHeader()}
         {tabs()}
        </div>
        {chatWindow()}
      </div>
      {archiveBtn(job)}
      {toggleEmailButton()}
      <Button className="btn btn-primary " label="Send Email Reminder" onClick={handleEmail} />
      <Button className="btn btn-outline-danger" label="Send Payment Reminder" onClick={() => handleEmail("payment")} />
    </div>
  </>

}