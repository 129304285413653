import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

export default function LoadingPage() {
    return <div data-cy="not-found-page" className="not-found container-fluid text-center">
        <div className='row'>
            <div className='col'>
                <div className="float-end spinner-border spinner-border-lg text-primary" role="status">
                    <span className='visually-hidden'>Loading</span>
                </div>
            </div>
            <div className='col text-start'>
                <p className='fs-4'>Loading...</p>
            </div>
            <Link to="/" className="link-button" data-cy="back-to-home-link">
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>Back to Home</span>
            </Link>
        </div>
    </div>
}