import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../user-context";
import { loginWithDuke } from "../helpers";
import { Machine } from "../models/machine";
import { Material } from "../models/material";
import { MaterialAttribute } from "../models/machineAttribute";

export default function ServicePage() {
    const { user, setUser } = useContext(UserContext);
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/createJob`;
        navigate(path);
    }
    const [printers, setPrinters] = useState([]);
    //adding materials
    const [materials, setMaterials] = useState([]);
    const [filteredMaterials, setFilteredMaterials] = useState([]);
    const [show, setShow] = useState(false);
    const [machineMaterials, setMachineMaterials] = useState([]);
    const [materialMachines, setMaterialMachines] = useState([]);
    const [materialAttributes, setMaterialAttributes] = useState([]);
    const [materialAttribute, setMaterialAttribute] = useState(false);

    const getPrinters = () => {
        Machine.filter("availability", "true").then((response) => {
            if (response.response) {
                console.log(response);
            } else {
                // console.log(response);
                setPrinters(response.data);
            }
        });
    }

    const getMaterials = () => {
        Material.filter("display", "true").then((response) => {
            if (response.response) {
                console.log(response);
            } else {
                // console.log(response);
                setMaterials(response.data);
                setFilteredMaterials(response.data);
            }
        });
    }

    const getMaterialsByAttribute = () => {
        const filtered_materials = materials.filter((material) => material.material_attributes.some(att => att.id === materialAttribute));
        setFilteredMaterials(filtered_materials);
    }

    const getAttributes = () => {
        MaterialAttribute.all().then((response) => {
            if (response.response) {
                console.log(response);
            } else {
                // console.log(response);
                setMaterialAttributes(response.data);
            }
        });
    }

    useEffect(() => {
        getPrinters();
        getMaterials();
        getAttributes();
    }, [])

    useEffect(() => {
        if (materialAttribute) {
            getMaterialsByAttribute();
        } else {
            setFilteredMaterials(materials);
        }
    }, [materialAttribute])

    const handleClick = async (e) => {
        let id = e.target.id;
        let machine = await Machine.get(id);
        let material = await Material.get(id);
        if (show === id) {
            id = false;
        }
        setShow(id);
        setMachineMaterials(machine.data.materials);
        setMaterialMachines(material.data.machines);
    }

    const pillBadge = (text, color) => {
        return <span className={`badge rounded-pill ${color}`}>{text}</span>
    }

    const machineAccordion = (p, index) => {
        return (
            <div className="accordion-item" key={"p" + index} data-cy="accordian-machines">
                <h4 className="accordion-header" id={p.id}>
                    <button className={show === p.id + '' ? "accordion-button" : "accordion-button collapsed"} id={p.id} onClick={(e) => handleClick(e)} type="button" data-bs-toggle="collapse" data-bs-target={p.id} aria-expanded="false" aria-controls={p.id}>
                        <strong>{p.name}</strong>
                    </button>
                </h4>
                <div className={show === p.id + '' ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="p.id" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <div className="material-pills">
                            <h5>Associated Materials:</h5>
                            {machineMaterials?.map((p, index) => <p key={index} data-cy="machine-material">{p?.name}{p?.pricing_tier?.name ? pillBadge(p?.pricing_tier?.name, "text-bg-primary") : ''}
                                {p?.availability?.name ? pillBadge(p?.availability?.name, "text-bg-light") : ''}</p>)}
                        </div>
                        {/* {p.description} */}
                        <div dangerouslySetInnerHTML={{ __html: p.description }} />
                    </div>
                </div>
            </div>
        )
    }

    const materialAccordion = (p, index) => {
        return (
            <div className="accordion-item" key={"p" + index} data-cy="accordian-materials">
                <h4 className="accordion-header" id={p.id}>
                    <button className={show === p.id + '' ? "accordion-button" : "accordion-button collapsed"} id={p.id} onClick={(e) => handleClick(e)} type="button" data-bs-toggle="collapse" data-bs-target={p.id} aria-expanded="false" aria-controls={p.id}>
                        <strong>{p.name}</strong>
                        <span>
                            {p?.pricing_tier?.name ? pillBadge(p?.pricing_tier?.name, "text-bg-primary") : ''}{' '}
                            {p?.availability?.name ? pillBadge(p?.availability?.name, "text-bg-light") : ''}</span>
                    </button>
                </h4>
                <div className={show === p.id + '' ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="p.id" data-bs-parent="#accordionExample" data-cy="material-machine">
                    <div className="accordion-body">
                        <p><b>Cost:</b> ${p.cost}/{p.unit}</p>
                        <div className="material-pills">
                            <h5><b>Associated Printers:</b></h5>
                            {materialMachines?.map((p, index) => <p key={index}>{p?.name}</p>)}
                        </div>
                        {/* {p.description} */}
                        <div dangerouslySetInnerHTML={{ __html: p.description }} />
                    </div>
                </div>
            </div>
        )
    }

    const showMaterialMaterials = () => {
        return <div className="col flex-items">
            <select onChange={e => setMaterialAttribute(parseInt(e.target.value, 10))} className="form-select" aria-label="attribute" id="attribute" value={materialAttribute}>
                <option hidden>Select a Material Attribute</option>
                <option value=''>Show All</option>
                {materialAttributes.map((ma, index) => <option value={ma.id} key={ma.name + index}>{ma.name}</option>)}
            </select>
        </div>
    }

    return <div className="container wrapper services">
        <div className="main">
            <h1>Capabilities</h1>
            {user ? <Button className="btn btn-primary submit-job" dataCy="submit-a-job" label="Submit a Job" onClick={routeChange} /> : <Button className="btn btn-primary" dataCy="login" onClick={loginWithDuke} label="Login to Submit A Job" />}
            <div>
                <p className="lh-lg">If you'd like to use a material not on our list or currently out of stock, expect a 2 week or longer lead time in ordering new materials. If the new material is on any of the Stratasys machines or the MJP expect to have a Bluesmith technician to reach out for a consultation as material changes on those printers require reconfiguration of the machine and additional material purge costs may be incurred.</p>
                <h2>Machines</h2>
                <div className="accordion" id="accordionExample">
                    {printers.map((p, index) => machineAccordion(p, index))}
                </div>
                <div className="flex">
                    <h2 className="flex-items">Materials</h2>
                    {showMaterialMaterials()}
                </div>
                {filteredMaterials.length > 0?
                <div className="accordion" id="accordionExample">
                    {filteredMaterials.map((p, index) => materialAccordion(p, index))}
                </div>:
                <p>There is no material with this attribute!</p>
                }
            </div>
        </div>
    </div>
}