import { useEffect, useState } from "react";
import axios from "axios";
import Select from 'react-select';
import { PricingTier } from "../../models/pricingTier";
import { Availability } from "../../models/availability";
import { MaterialAttribute } from "../../models/machineAttribute";

export default function MaterialForm(props) {
    const [material, setMaterial] = useState(props.material);
    // const [alert, setAlert] = useState(false);
    const [pricingTiers, setPricingTiers] = useState([]);
    const [pricingTier, setPricingTier] = useState();
    const [availabilities, setAvailabilities] = useState([]);
    const [availability, setAvailability] = useState();
    const [attributes, setAttributes] = useState([]);
    const [select, setSelect] = useState(props.material.material_attributes);

    const getPricingTiers = () => {
        PricingTier.all().then((response) => {
            if (response.response) {
                console.log(response);
            } else {
                //   console.log(response);
                setPricingTiers(response.data);
            }
        });
    }

    const getAvailabilities = () => {
        Availability.all().then((response) => {
            if (response.response) {
                console.log(response);
            } else {
                //   console.log(response);
                setAvailabilities(response.data);
            }
        });
    }

    const getAttributes = () => {
        MaterialAttribute.all().then((response) => {
            if (response.response) {
                console.log(response);
            } else {
                //   console.log(response);
                let attributeList = [];
                response.data.map(d => {
                    attributeList.push({ value: d.id, label: d.name })
                })
                setAttributes(attributeList);
            }
        });
    }

    useEffect(() => {
        getPricingTiers();
        getAvailabilities();
        getAttributes();
    }, []);

    useEffect(() => {
        setSelect(props.defaultAttributes);
    }, [props.defaultAttributes])

    useEffect(() => {
        setMaterial(props.material);
        setPricingTier(props.material.pricing_tier_id);
        setAvailability(props.material.availability_id);
    }, [props.material])

    const handleChange = (e) => {
        let name;
        let value;
        if (e.target){
            name = e.target.id;
            value = e.target.value;
            if (name === "pricing_tier_id") {
                setPricingTier(value);
            } else if (name === "availability_id") {
                setAvailability(value);
            } 
        } else {
            name = "material_attributes"
            value = [];
            let newSelect = [];
            e.map(data => {
                value.push(data.value);
                newSelect.push({ label: data.label, value: data.value });
            })
            setSelect(newSelect);
        }
        let newMaterial = material;
        newMaterial[name] = value;
        console.log(name, value);
        setMaterial(newMaterial);
    }

    const materialName = () => {
        return <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label><span className="red-star">*</span>
            <input onChange={e => handleChange(e)} type="text" className="form-control" id="name" aria-describedby="name" placeholder="A short descriptive name to identify this material." required defaultValue={material?.name} />
        </div>
    }

    // const materialSummary = () => {
    //     return <div className="mb-3">
    //         <label htmlFor="summary" className="form-label">Summary</label><span className="red-star">*</span>
    //         <input onChange={e => handleChange(e)} type="text" className="form-control" id="summary" aria-describedby="summary" placeholder="A short summary to identify this material." required defaultValue={material?.summary} />
    //     </div>
    // }

    const materialDescription = () => {
        return <div className="mb-3">
            <label htmlFor="description" className="form-label">Description</label><span className="red-star">*</span>
            <textarea onChange={e => handleChange(e)} type="text" className="form-control" id="description" rows="10" aria-describedby="description" placeholder="A short description to identify this material." required defaultValue={material?.description}></textarea>
        </div>
    }

    const materialCost = () => {
        return <div className="mb-3">
            <div className="row">
                <div className="col">
                    <label htmlFor="cost" className="form-label">Cost</label>
                    <input onChange={e => handleChange(e)} type="number" className="form-control" id="cost" aria-describedby="cost" placeholder="A cost for this material." defaultValue={material?.cost} />
                </div>
                <div className="col">
                    <label htmlFor="unit" className="form-label">Unit</label>
                    <input onChange={e => handleChange(e)} type="text" className="form-control" id="unit" aria-describedby="unit" placeholder="A unit for this material." defaultValue={material?.unit} />
                </div>
            </div>
        </div>
    }

    const materialPricingTier = () => {
        return <div className="mb-3">
            <label htmlFor="pricing_tier_id" className="form-label">Pricing Tier</label><span className="red-star">*</span>
            <select onChange={e => handleChange(e)} className="form-select" aria-label="pricing_tier_id" id="pricing_tier_id" required value={pricingTier || ''}>
                <option hidden>None</option>
                {pricingTiers.map((p, index) => <option value={p.id} key={p.id + index}>{p.name}</option>)}
            </select>
        </div>
    }

    const materialAvailability = () => {
        return <div className="mb-3">
            <label htmlFor="availability_id" className="form-label">Availability</label><span className="red-star">*</span>
            <select onChange={e => handleChange(e)} className="form-select" aria-label="availability_id" id="availability_id" required value={availability || ''}>
                <option hidden>None</option>
                {availabilities.map((p, index) => <option value={p.id} key={p.id + index}>{p.name}</option>)}
            </select>
        </div>
    }

    const materialAttribute = () => {
        return <div className="mb-3">
            <label htmlFor="attributes" className="form-label">Attributes</label>
            <Select isMulti options={attributes} id="attributes" onChange={e => handleChange(e)} value={select} />
        </div>
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (material.pricing_tier_id && material.availability_id) {
            const form = new FormData();

            console.log(form);
            form.append("name", material.name);
            // form.append("summary", material.summary);
            form.append("description", material.description);
            form.append("cost", material.cost);
            form.append("unit", material.unit);
            form.append("pricing_tier_id", material.pricing_tier_id);
            form.append("availability_id", material.availability_id);
            form.append("material_attributes", material.material_attributes);

            const options = {
                method: props.method,
                url: props.url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                },
                data: form
            };

            axios.request(options).then(function (response) {
                console.log(response.data);
                if (response.data.error) {
                    // setAlert(response.data);
                } else {
                    window.location.pathname = './admin/materials';
                }
            }).catch(function (error) {
                console.error(error);
            });
        } else {
            alert("Pricing Tier and Availability are required!");
        }
    }

    const deleteForm = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete this Material?")) {

            const options = {
                method: "DELETE",
                url: props.url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                }
            };

            axios.request(options).then(function (response) {
                console.log(response.data);
                if (response.data.error) {
                    // setAlert(response.data);
                } else {
                    window.location.pathname = `./admin/materials`;
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    }
    return <>
        <form>
            {materialName()}
            {/* {materialSummary()} */}
            {materialDescription()}
            {materialCost()}
            {materialPricingTier()}
            {materialAvailability()}
            {materialAttribute()}
            <button onClick={submitForm} data-cy="create-update-button" type="submit" className="btn btn-primary">{props.edit ? "Update" : "Create"}</button>
            <span>{' '}</span>
            {props.edit ? <button onClick={deleteForm} type="button" className="btn btn-danger">Delete</button> : ''}
            {!props.edit ? <button onClick={() => window.location.pathname = "./admin/printers"} type="button" className="btn btn-light">Cancel</button> : ''}
        </form>
    </>
}